import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Bubble = keyframes`
  0% {
		box-shadow: 0 0 0 3px rgba(200,255,255,0.3);
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		box-shadow: 0 0 0 100px rgba(200,255,255,0);
		opacity: 0;
		transform: scale3d(1, 1, 1);
	}
`;

const VideoBlockWrapper = styled.div`
  position: relative;
`;

export const VideoModal = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 4px ${themeGet('brand.brand8Shade10')};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      -138deg,
      ${themeGet('brand.brand8Tint40')} 0%,
      ${themeGet('brand.brand6')} 80%
    );
    opacity: 0.1;
    pointer-events: none;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
    border-radius: 8px;
  }
`;

export const PlayButton = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid #fff;
  background: ${themeGet('brand.brand4')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
  outline: 0;
  z-index: 2;
  box-shadow: 0 0 10px ${themeGet('brand.brand8Tint40')};

  svg {
    position: relative;
    left: 2px;
  }

  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
    svg {
      width: 30px;
      line-height: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    top: 0;
    left: 0;
    border-radius: inherit;
  }

  &:focus {
    &:after {
      animation: ${Bubble} 0.5s ease-out forwards;
    }
  }
`;

export const VideoWrapper = styled.div`
  max-width: calc(100% - 30px);
  width: 900px;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default VideoBlockWrapper;
