import React from 'react';
import PropTypes from 'prop-types';

// Components
// =============================================================================
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Image from 'common/src/components/Image';

// Styles
// =============================================================================
import FeaturesBlockWrapper from './FeaturesBlock.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// FeaturesBlock
// =============================================================================
const FeaturesBlock = ({
  FeaturesBlockItemWrapperStyle,
  FeaturesBlockItemIconStyle,
  FeaturesBlockItemHeadingStyle,
  FeaturesBlockItemTextStyle,
  dataFeatures,
}) => {
  return (
    <FeaturesBlockWrapper>
      {dataFeatures.map((item, index) => (
        <FeatureBlock
          key={`feature-item-${index}`}
          icon={
            <Image
              src={item.icon.publicURL}
              alt={`feature-item-icon-${index + 1}`}
            />
          }
          wrapperStyle={FeaturesBlockItemWrapperStyle}
          iconStyle={FeaturesBlockItemIconStyle}
          iconPosition="left"
          title={
            <Heading content={item.title} {...FeaturesBlockItemHeadingStyle} />
          }
          description={
            <Text content={item.description} {...FeaturesBlockItemTextStyle} />
          }
        />
      ))}
    </FeaturesBlockWrapper>
  );
};

FeaturesBlock.propTypes = {
  FeaturesBlockItemWrapperStyle: PropTypes.object,
  FeaturesBlockItemIconStyle: PropTypes.object,
  FeaturesBlockItemHeadingStyle: PropTypes.object,
  FeaturesBlockItemTextStyle: PropTypes.object,
};

FeaturesBlock.defaultProps = {
  FeaturesBlockItemWrapperStyle: {
    alignItems: 'bottom',
    m: '40px 0',
  },
  FeaturesBlockItemIconStyle: {
    width: ['80px', '80px', '80px', '80px', '80px'],
    pr: '20px',
  },
  FeaturesBlockItemHeadingStyle: {
    fontSize: ['18px', '19px'],
    fontWeight: '600',
    textAlign: 'left',
    color: brand.brand8,
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: ['5px'],
  },
  FeaturesBlockItemTextStyle: {
    fontSize: '18px',
    fontWeight: '400',
    textAlign: 'left',
    color: brand.brand8,
    letterSpacing: '-0.015em',
    lineHeight: '1.5',
    mb: 0,
  },
};

export default FeaturesBlock;
