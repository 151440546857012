import styled from 'styled-components';
//import { themeGet } from '@styled-system/theme-get';

const SectionAreaWrapper = styled.div`
  padding: 20px 0;

  @media (min-width: 320px) {
  }
  @media (min-width: 576px) {
    padding: 40px;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 991px) {
    padding: 0 40px;
  }
  @media (min-width: 1220px) {
    padding: 0 40px;
  }
`;

export const SectionArea = styled.div`
  position: relative;

  @media (min-width: 320px) {
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 991px) {
  }
  @media (min-width: 1220px) {
  }
`;

export default SectionAreaWrapper;
