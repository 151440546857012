import React from 'react';
import { openModal, closeModal } from '@redq/reuse-modal';
import Icon from 'react-icons-kit';
import { play } from 'react-icons-kit/entypo/play';

// Components
// =============================================================================
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';

// Styles
// =============================================================================
import VideoBlockWrapper, {
  VideoModal,
  PlayButton,
  VideoWrapper,
} from './VideoBlock.style';

// Colours
// =============================================================================
import BannerImage from 'common/src/assets/image/eComm360shopify/video-image.png';

// Close modal button
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

// Modal content
const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="eComm360 Video"
      src="https://www.youtube.com/embed/_D30xNttd7o"
      frameBorder="0"
    />
  </VideoWrapper>
);

// VideoBlock
// =============================================================================
const VideoBlock = () => {
  // Modal
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  return (
    <VideoBlockWrapper>
      <VideoModal>
        <Image src={BannerImage} alt="eComm360 Video" />
        <PlayButton tabIndex="0" onClick={handleVideoModal}>
          <Icon icon={play} size={40} />
        </PlayButton>
      </VideoModal>
    </VideoBlockWrapper>
  );
};

export default VideoBlock;
